@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
* {
	font-family: "Rubik", sans-serif !important;
}
body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
